import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Layout from '../components/LayoutV2'
import Container from '../components/Container'

import LOGO_CONTACT_SMARTER from '../images/cs-logo-color.svg'

const HeaderContent = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: 1280px) {
    padding: 0 40px;
  }
`

const Logo = styled.img`
  width: 152px;
  height: 24px;
  object-fit: contain;
`

const Header = styled.div`
  margin: 0 auto;
  padding: 32px 0;
  position: relative;
  z-index: 1000;

  @media (max-width: 1280px) {
    width: calc(100vw - 160px);
  }

  @media (max-width: 544px) {
    width: 100vw;
    min-height: 44px;
  }
`

const Wrapper = styled.div`
  font-family: CircularStd;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  min-height: calc(100vh - 290px);
`

const Content = styled.div`
  max-width: 840px;
  margin: 0 auto;
  padding: 128px 0;
  text-align: center;

  .title {
    font-size: ${props => props.theme.unit.getRem(31)};
    font-weight: 600;

    .blue {
      color: ${props => props.theme.colors.primary};
    }
  }

  .video {
    margin: 32px auto 48px auto;
    max-width: 600px;
  }

  @media (max-width: 768px) {
    padding: 48px 0;

    .title {
      font-size: ${props => props.theme.unit.getRem(18)};
    }
  }
`

const Button = styled.a`
  background-color: ${props => props.theme.colors.primary};
  border-radius: 8px;
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.unit.getRem(24)};
  font-weight: 600;
  max-width: 456px;
  padding: 16px 24px;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: ${props => props.theme.unit.getRem(16)};
  }
`

const WatchTrainingContainer = styled.div`
  display: flex;
  margin-top: 48px;
  flex-direction: column;
  align-items: center;

  ${Button} {
    margin-bottom: 8px;
  }
`

const Footer = styled.div`
  border-top: solid 1px #e5e7ec;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray};
  text-align: center;
  max-width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 56px;

  ${Logo} {
    margin-bottom: 16px;
  }

  span {
    font-size: ${props => props.theme.unit.getRem(16)};
    margin-bottom: 4px;
  }

  * {
    margin: 0 auto;
    display: block;
  }
`

const Mortgage = () => {
  const [isWatchTraining, setIsWatchTraining] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    !(function(f, b, e, v, n, t, s) {
      if (f.fbq) return
      n = f.fbq = function() {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    )

    window.analytics.track('MortageFunnel Loaded')
    window.fbq('init', '278790859430446')
    window.fbq('track', 'PageView')
  }, [])

  function watchTrainingContainer() {
    window.analytics.track('MortgageFunnel Clickthrough')

    setIsWatchTraining(true)
  }

  return (
    <Layout>
      <Header className="container">
        <HeaderContent>
          <Logo src={LOGO_CONTACT_SMARTER} />
        </HeaderContent>
      </Header>

      <Wrapper>
        {isWatchTraining ? (
          <Container>
            <Content>
              <span className="title">
                Watch How To{' '}
                <span className="blue">Convert More Than 70% Of Leads</span>{' '}
                Into Conversations
              </span>
              <div className="video">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
                    <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/gec4g5szxf?videoFoam=true" title="Untitled Video" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%"></iframe></div></div>
<script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>`,
                  }}
                />
              </div>
              <Button href="https://b.contactsmarter.com/c/2kbwzm87vdLfuxDteDfGjH">
                Click to book a free demo
              </Button>
            </Content>
          </Container>
        ) : (
          <Container>
            <Content>
              <span className="title">
                Free Training: Watch The{' '}
                <span className="blue">#1 Growth Tool</span> That{' '}
                <span className="blue">Converted 150,000 Mortgage Leads</span>{' '}
                Into Phone Calls
              </span>
              <WatchTrainingContainer>
                <Button onClick={() => watchTrainingContainer()}>
                  Watch training now
                </Button>
                <span>
                  This is 100% free training, no credit card required.
                </span>
              </WatchTrainingContainer>
            </Content>
          </Container>
        )}
      </Wrapper>

      <Footer>
        <Logo src={LOGO_CONTACT_SMARTER} />
        <span>© Copyright 2019 ContactSmarter</span>
        <span>All rights reserved</span>
      </Footer>
    </Layout>
  )
}

export default Mortgage
