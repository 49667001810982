import React from 'react'
import PropTypes from 'prop-types'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import CircularStdBold from '../fonts/CircularStd-Bold.woff'
import CircularStdMedium from '../fonts/CircularStd-Medium.woff'
import CircularStdBook from '../fonts/CircularStd-Book.woff'

import theme from '../theme'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'CircularStd';
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    src: url(${CircularStdBold}) format('woff');
  }

  @font-face {
    font-family: 'CircularStd';
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    src: url(${CircularStdMedium}) format('woff');
  }

  @font-face {
    font-family: 'CircularStd';
    src: url(${CircularStdBook}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }

  html {
    color: ${props => props.theme.colors.black};
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: CircularStd;
    letter-spacing: normal;
    -webkit-font-smoothing: antialiased;
  }

  .container {
    margin: 0 auto;
    max-width: 1280px;
  }

  .overflow {
    font-family: CircularStd;
    letter-spacing: normal;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
  }
`

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <GlobalStyle />
      <div className="overflow">{children}</div>
    </React.Fragment>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
